import React from 'react'
import Banner from './components/Banner'

import DashboardPage from './pages/DashboardPage'
import AdminPage from './pages/AdminPage'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    window.customStyles = props.content.Dashboard[0]
  }

  render() {
    return (
      <div className="overview">
        <Banner content={this.props.content.Dashboard[0]} />
        <div className="page-width">
            {this.props.page === 'admin' && (
              <AdminPage {...this.props} />
              )}
            {this.props.page !== 'admin' && (
              <DashboardPage {...this.props} />
            )}
        </div>
      </div>
    )
  }
}


export default Dashboard
