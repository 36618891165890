import React from 'react'
import Select from 'react-select'
import Modal from 'react-modal'
import SearchTable from '../vendor/SearchTable'
import moment from 'moment'
import BeatLoader from "react-spinners/BeatLoader"
import ClipLoader from "react-spinners/ClipLoader"

import UserService from "../services/UserService"
import MissionService from "../services/MissionService"

import {SmallSelectStyles} from '../styles/select-styles'
import {ModalStyles} from '../styles/modal-styles'

class AdminPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      missionChangePending: false,
      adjustmentPending: false,
      removalPending: false,
      viewingHistory: false,
      viewingAllUsers: false,
      users: this.props.users,
      missions: this.props.missions,
    }
  }

  componentDidMount() {
    UserService.fetchAll().then((response) => {
      this.setState({ users: response })
    })
  }

  userRow = (user) => {
    return {
      Name: user.name,
      Email: user.email,
      Crystanima: user.crystanima,
      '':
          <a onClick={() => {
            this.setState({viewingHistory: user.id})
          }}>edit</a>,
      Active: user.active ? 'Yes' : 'No',
    }
  }

  addAdjustment = () => {
    if (this.state.adjDescription && this.state.adjQuantity && !this.state.adjustmentPending) {
      this.setState({ adjustmentPending: true }, () => {
        UserService.addAdjustment(
          this.state.viewingHistory,
          this.state.adjDescription,
          this.state.adjQuantity
        ).then(response => {
          this.setState(prevState => {
            let users = [...prevState.users]
            users[users.findIndex(user => user.id === response.id)] = response
            return {
              adjustmentPending: false,
              adjDescription: undefined,
              adjQuantity: undefined,
              users: users,
            }
          })
        }).catch(() => this.setState({ adjustmentPending: false }))
      })
    }
  }

  removeAdjustment = (adjustment) => {
    if (!this.state.removalPending) {
      this.setState({ removalPending: adjustment }, () => {
        UserService.removeAdjustment(
          this.state.viewingHistory,
          adjustment
        ).then(response => {
          this.setState(prevState => {
            let users = [...prevState.users]
            users[users.findIndex(user => user.id === response.id)] = response
            return {
              removalPending: false,
              users: users,
            }
          })
        }).catch(() => this.setState({ removalPending: false }))
      })
    }
  }

  render() {
    const missionOptions = [
      { label: "Pending", value: "pending" },
      { label: "In progress", value: "in_progress" },
      { label: "Completed", value: "completed" },
      { label: "Archived", value: "archived" },
    ]

    const users = this.state.users.filter(user => this.state.viewingAllUsers || user.active).map(user => this.userRow(user))
    const viewingUser = this.state.users.find(user => user.id === this.state.viewingHistory)

    return (
      <div className="introduction admin">
        <h1>Crystanima Admin</h1>

        <section>
          <div className="user-bar with-divider">
            <h3>Mission Board</h3>
            <a onClick={() => {
              let headers = new Headers()
              headers.set('Authorization', 'Basic ' + btoa("logout:user"))
              fetch(`/admin`, { headers: headers }).then(() => window.location = '/logout')
            }}>Log out</a>
          </div>

          <table className="mission-board">
            <thead>
              <tr>
                <th>Mission</th>
                <th>Requested by</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.missions.map((mission) => {
                return (
                  <tr key={`${mission.id}-${mission.updated_at}`}>
                    <td>{mission.description}</td>
                    <td>{mission.requested_by}</td>
                    <td className={mission.status == "Completed" || mission.status == "Archived" ? 'status completed' : 'status'}>
                      <Select
                        styles={SmallSelectStyles}
                        components={{ IndicatorSeparator:() => null }}
                        isDisabled={this.state.missionChangePending}
                        isSearchable={false}
                        menuPlacement="auto"
                        value={missionOptions.find((option) => {
                          return option.label == mission.status
                        })}
                        options={missionOptions}
                        onChange={(selected) => {
                          this.setState({ missionChangePending: mission.id }, () => {
                            MissionService.updateMission(mission, selected.value).then((response) => {
                              this.setState({ missionChangePending: false, missions: response })
                            }).catch(() => this.setState({ missionChangePending: false }))
                          })
                        }}
                      />
                      <a title="Cancel" className={this.state.missionChangePending == mission.id ? 'pending' : ''} onClick={() => {
                        this.setState({ missionChangePending: mission.id }, () => {
                          this.setState({ missionChangePending: mission.id }, () => {
                            MissionService.removeMission(mission).then((response) => {
                              this.setState({ missionChangePending: false, missions: response })
                            }).catch(() => this.setState({ missionChangePending: false }))
                          })
                        })
                      }}>{this.state.missionChangePending == mission.id ? "◌" : "❌"}</a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <h3 className="user-bar with-divider">
            User list
            <div className="toggle">
              <span
                className={this.state.viewingAllUsers ? "inactive" : "active"}
                onClick={() => this.setState({ viewingAllUsers: false })}
              >
                active only
              </span>
              /
              <span
                className={this.state.viewingAllUsers ? "active" : "inactive"}
                onClick={() => this.setState({ viewingAllUsers: true })}
              >
                all
              </span>
            </div>
          </h3>

          {this.state.users.length > 0 && (
            <SearchTable searchPrompt="Search" rows={users} rowsPerPage={20} />
          )}

          {this.state.users.length === 0 && (
            <div className="loader">
              <BeatLoader loading={true} color="white" />
            </div>
          )}

          {this.state.viewingHistory && (
            <Modal
              isOpen={true}
              onRequestClose={() => this.setState({ viewingHistory: false, adjDescription: undefined, adjQuantity: undefined })}
              ariaHideApp={false}
              style={ModalStyles}
              parentSelector={() => document.body}
            >
              <div className="modal-container">
                <h3>Crystanima History ({viewingUser.name})</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Event</th>
                        <th className="number">Amount</th>
                        <th className="date">Date</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {viewingUser.events.map((event, index) => {
                        return (
                          <tr key={index}>
                            <td>{event.description}</td>
                            <td className="number">
                              {event.quantity}
                            </td>
                            <td className="date">{moment(event.date).format("LL")}</td>
                            <td>
                              {event.type === 'adjustment' && !this.state.removalPending && (
                                <a onClick={() => this.removeAdjustment(event.id)}>❌</a>
                              )}
                              {this.state.removalPending === event.id && (
                                <ClipLoader loading={true} color="white" size={15} />
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>

                  <div className="with-divider">Add adjustment</div>
                  <div className="adjustment-form">
                    <input
                      type="text"
                      placeholder="Description"
                      value={this.state.adjDescription || ''}
                      onChange={(event) => this.setState({ adjDescription: event.target.value })}
                    />
                    <input
                      type="number"
                      placeholder="Quantity"
                      value={this.state.adjQuantity || ''}
                      onChange={(event) => this.setState({ adjQuantity: event.target.value })} />
                    <a
                      className={`button ${this.state.adjDescription && this.state.adjQuantity && !this.state.adjustmentPending ? '' : 'disabled'}`}
                      onClick={this.addAdjustment}
                    >
                      Submit
                    </a>
                  </div>
                  {this.state.adjustmentPending && (
                    <div className="adjustment-loader">
                      <BeatLoader loading={true} color="white" />
                    </div>
                  )}
                </div>
            </Modal>
          )}
        </section>
      </div>
    )
  }
}

export default AdminPage
