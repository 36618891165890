class UserService {
  async fetchAll() {
    return await fetch(`/admin/users`).then(response => response.json())
  }

  async addAdjustment(user, description, quantity) {
    return await fetch(
      `/admin/users/${user}/crystanima_events?description=${description}&quantity=${quantity}`,
      { method: 'POST' }
    ).then(response => response.json())
  }

  async removeAdjustment(user, adjustment) {
    return await fetch(
      `/admin/users/${user}/crystanima_events/${adjustment}`,
      { method: 'DELETE' }
    ).then(response => response.json())
  }
}

export default new UserService()
