export const ModalStyles = {
  overlay: {
    backgroundColor: 'rgba(72, 84, 100, 0.6)',
    zIndex: 99,
  },
  content: {
    padding: '3rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    backgroundColor: '#23232e',
    boxShadow: '0.4rem 0.8rem 2rem rgba(0,0,0,0.15)',
  },
}
