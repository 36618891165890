import React from 'react'

class Banner extends React.Component {
  fill = () => {
    return `rgba(${this.props.content.outer_background.red}, ${this.props.content.outer_background.green}, ${this.props.content.outer_background.blue})`
  }

  render() {
    return (
      <div className="banner" style={{backgroundImage: `url(${this.props.content.banner_url})`}}>
        <div className="heightbox" />
        <svg className="curve" width="500" height="120" viewBox="0 0 500 120" preserveAspectRatio="none">
          <path d="M0,0 Q250,120 500,0 L 500,120 0,120 Z" fill={this.fill()} />
        </svg>
      </div>
    )
  }
}

export default Banner
