export const SelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '1rem 0.8rem',
    backgroundColor: fill(window.customStyles.outer_background),
    color: fill(window.customStyles.font_color),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: fill(window.customStyles.inner_background),
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: '1.6rem',
    fontWeight: 375,
  }),
  control: (provided, state) => ({
    display: 'flex',
    padding: '0.3rem 0 0.3rem 0.6rem',
    minWidth: '20rem',
    width: state.selectProps.width,
    backgroundColor: fill(window.customStyles.outer_background),
    borderRadius: '0.5rem',
    minHeight: '5.4rem',
    border: '0.2rem solid',
    borderColor: fill(window.customStyles.outer_background),
    color: fill(window.customStyles.font_color),
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
  }),
  singleValue: (provided, state) => ({
    width: 'calc(100% - 3rem)',
    color: fill(window.customStyles.font_color),
    fontSize: '1.6rem',
    fontWeight: 375,
    '.explanation': {
      display: 'none',
    },
    '.description': {
      display: 'none',
    },
    'h4': {
      fontSize: '1.7rem !important',
      margin: '0 !important',
    }
  }),
}

export const SmallSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '0.2rem 1rem',
    fontSize: '1.5rem',
    backgroundColor: fill(window.customStyles.outer_background),
    color: fill(window.customStyles.font_color),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: fill(window.customStyles.inner_background),
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: '1.6rem',
    fontWeight: 375,
  }),
  control: (provided, state) => ({
    display: 'flex',
    padding: '0',
    minWidth: '20rem',
    width: state.selectProps.width,
    backgroundColor: 'unset',
    borderRadius: '0.5rem',
    minHeight: '2rem',
    border: '0.2rem solid',
    borderColor: 'transparent',
    color: fill(window.customStyles.font_color),
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
  }),
  singleValue: (provided, state) => ({
    width: 'calc(100% - 3rem)',
    color: fill(window.customStyles.font_color),
    padding: 0,
    fontSize: '1.6rem',
    fontWeight: 375,
    '.explanation': {
      display: 'none',
    },
    '.description': {
      display: 'none',
    },
    'h4': {
      fontSize: '1.7rem !important',
      margin: '0 !important',
    }
  }),
}

const fill = (color) => {
  return `rgba(${color.red}, ${color.green}, ${color.blue})`
}
