class MissionService {
  async updateMission(mission, status) {
    return await fetch(
      `/admin/missions/${mission.id}?mission_status=${status}`,
      { method: 'PUT' }
    ).then(response => response.json())
  }

  async removeMission(mission) {
    return await fetch(
      `/admin/missions/${mission.id}`,
      { method: 'DELETE' }
    ).then(response => response.json())
  }
}

export default new MissionService()
