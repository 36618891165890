import React from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import moment from 'moment'
import parse from 'html-react-parser'

import {SelectStyles} from '../styles/select-styles'
import {ModalStyles} from '../styles/modal-styles'

import CrystanimaIcon from '../components/CrystanimaIcon'

class DashboardPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewingHistory: false,
      selectedMission: null,
      requestPending: false,
    }
  }

  missionValid = () => {
    return this.state.selectedMission &&
      this.state.selectedMission.cost <= this.props.session.crystanima &&
      !this.state.requestPending
  }

  render() {
    const content = this.props.content.Dashboard[0]
    return (
      <div className="introduction dashboard">
        <h1>{content.title}</h1>
        <div className="user-paragraph">{parse(content.introduction)}</div>

        {!this.props.session && (
          <div className="login">
            <div>
              <a className="button" href="/auth/patreon">Log in with Patreon</a>
              {this.props.login_error && (
                <div className="error">{this.props.login_error}</div>
              )}
            </div>
          </div>
        )}

        {this.props.session && (
          <section>
            <div className="user-bar with-divider">
              <span>
                Logged in as <span className="name">{this.props.session.name}</span>
              </span>

              <a href="/logout">Log out</a>
            </div>

            <div className="balance">
              <h3>Crystanima Balance</h3>
              <div>
                {this.props.session.crystanima}
                <CrystanimaIcon cost={this.props.session.crystanima} />
              </div>
              <a className="history" onClick={() => this.setState({ viewingHistory: true })}>
                view history
              </a>

              <Modal
                isOpen={this.state.viewingHistory}
                onRequestClose={() => this.setState({ viewingHistory: false })}
                ariaHideApp={false}
                style={ModalStyles}
                parentSelector={() => document.body}
              >
                <h3>Crystanima History</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th className="number">Amount</th>
                      <th className="date">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.session.events.map((event, index) => {
                      return (
                        <tr key={index}>
                          <td>{event.description}</td>
                          <td className="number">
                            {event.quantity}
                          </td>
                          <td className="date">{moment(event.date).format("LL")}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Modal>
            </div>

            <div className="user-paragraph">{parse(content.purchase_introduction)}</div>
            <form action={`/missions?id=${this.state.selectedMission?.id}`} method='POST'>
              <div className="mission-purchase">
                <Select
                  styles={SelectStyles}
                  placeholder='Request a mission'
                  noOptionsMessage={() => 'No missions available'}
                  components={{ IndicatorSeparator:() => null }}
                  formatOptionLabel={this.formatOption}
                  isClearable={true}
                  isSearchable={false}
                  menuPlacement="auto"
                  value={(this.props.content.Mission || []).find((mission) => {
                    return mission.id == this.state.selectedMission?.id
                  })}
                  options={this.props.content.Mission || []}
                  onChange={(selected) =>
                    this.setState({ selectedMission: selected })
                  }
                />
                <a
                  className={`button ${this.missionValid() ? '' : 'disabled'}`}
                  onClick={() => {
                    if (this.missionValid()) {
                      this.setState({ requestPending: true}, () => document.forms[0].submit())
                    }
                  }}
                >
                  Submit
                </a>
              </div>
            </form>

            <h3 className="with-divider">Mission Board</h3>
            <div className="user-paragraph">{parse(content.mission_board_introduction)}</div>

            <table className="mission-board">
              <thead>
                <tr>
                  <th>Mission</th>
                  <th>Requested by</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.props.missions.map((mission, index) => {
                  return (
                    <tr key={mission.id}>
                      <td>{mission.description}</td>
                      <td>{mission.requested_by}</td>
                      <td className={mission.status == "Completed" ? 'status completed' : 'status'}>
                        {mission.status}
                        {mission.user_id === this.props.session.id && mission.status == "Pending" && (
                          <a title="Cancel" className={this.state.requestPending ? 'pending' : ''} onClick={() => {
                            this.setState({ requestPending: true }, () => {
                              fetch(`/missions/${mission.id}`, { method: 'DELETE' })
                                .then(() => {
                                  window.location.reload()
                                })
                            })
                          }}>{this.state.requestPending ? "◌" : "❌"}</a>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <h3 className="with-divider">FAQ</h3>
            <div className="faq">
              {this.props.content.FaqItem.map(item => {
                return (
                  <div className="faq-item" key={item.id}>
                    <div className="question">{item.question}</div>
                    <div className="answer user-paragraph">{parse(item.answer)}</div>
                  </div>
                )
              })}
            </div>
          </section>
        )}
      </div>
    )
  }

  formatOption = (option) => {
    return (
      <div className="mission-option">
        <div className="details">
          <h4>
            <div className="title">
              <figure>
                <img src={option.scroll_url} />
              </figure>
              {option.title}
            </div>

            <div className={`cost ${this.props.session.crystanima < option.cost ? 'insufficient' : ''}`}>
              {option.cost}
              <figure>
                <CrystanimaIcon amount={option.cost} />
              </figure>
            </div>
          </h4>
          <div className="description">{option.description}</div>
        </div>

        <figure className="explanation">
          <img src={option.explanation_url} />
        </figure>
      </div>
    )
  }
}


export default DashboardPage
