import React from 'react'

class CrystanimaIcon extends React.Component {
  crystanimaIcon = (filterValue) => {
    const thresholds = Object.assign({ 4000: 5, 2000: 4, 1000: 3, 500: 2, 250: 1, 0: 0 })
    const amount = [4000, 2000, 1000, 500, 250, 0].find(value => value < filterValue)
    return `https://data.wowaudit.com/mitsukai/crystanima-${thresholds[amount] || 0}-small.png`
  }

  render() {
    return (
      <img
        className="crystanima-icon"
        src={this.crystanimaIcon(this.props.cost)}
        alt="Crystanima"
        title="Crystanima"
      />
    )
  }
}

export default CrystanimaIcon
